import React, { Fragment, useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import M from "materialize-css/dist/js/materialize.min.js";
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import CircularProgress from "@material-ui/core/CircularProgress";
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { uploadDeviceFactoryFirmware, downloadDeviceFactoryFirmwareFile, getDeviceFactoryFirmwareData } from "../../../action/firmware";
import { setAlert } from "../../../action/alert";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import CloudDownload from '@material-ui/icons/CloudDownload';
import customConfig from "../../../config";

const useStyles = makeStyles({
    selectInput: {
        height: "3rem",
        width: "100%"
    },
    selectValues: {
        width: "100%"
    },
    activeTab: {
        border: '1px solid #bdbdbd',
        backgroundColor: '#e0e0e0',
        textTransform: 'capitalize'
    },
    nonActiveTab: {
        border: 'none',
        backgroundColor: 'none',
        textTransform: 'capitalize'
    }
});

const headers = {
    firmwareVersion: "Firmware Version",
    deviceCount: "Device Count",
    //percentage: "Percentage",
    firmwareImage: "Firmware Image",
    firmwareSign: "Firmware Signature",
};

const DeviceFactoryFirmware = ({
    allowedDeviceCategories,
    allowedCompanyBrand,
    firmwareData,
    uploadDeviceFactoryFirmware,
    downloadDeviceFactoryFirmwareFile,
    setAlert
}) => {

    const classes = useStyles()
    const [activeTab, setActiveTab] = React.useState(0)

    return (<div className="row">
        <Tabs
            value={activeTab}
            onChange={(e, newValue) => {
                setActiveTab(newValue)
            }}
            TabIndicatorProps={{
                style: {
                    backgroundColor: "#bdbdbd"
                }
            }}
            style={{ marginTop: '20px' }}
        >
            <Tab label="View Firmware Info" className={activeTab == 0 ? classes.activeTab : classes.nonActiveTab} />
            <Tab label="Upload Firmware" className={activeTab == 1 ? classes.activeTab : classes.nonActiveTab} />
        </Tabs>
        <div hidden={activeTab != 0}>
            <ViewFirmwareInfo
                allowedDeviceCategories={allowedDeviceCategories}
                allowedCompanyBrand={allowedCompanyBrand}
                downloadDeviceFactoryFirmwareFile={downloadDeviceFactoryFirmwareFile}
                setAlert={setAlert}
            ></ViewFirmwareInfo>
        </div>
        <div hidden={activeTab != 1}>
            <UploadFirmware
                allowedDeviceCategories={allowedDeviceCategories}
                allowedCompanyBrand={allowedCompanyBrand}
                firmwareData={firmwareData}
                uploadDeviceFactoryFirmware={uploadDeviceFactoryFirmware}
                setAlert={setAlert}
            ></UploadFirmware>
        </div>
    </div>
    );
}

const ViewFirmwareInfo = ({
    allowedDeviceCategories,
    allowedCompanyBrand,
    setAlert,
    downloadDeviceFactoryFirmwareFile }) => {

    const classes = useStyles()

    const [categoryFilter, setCategoryFilter] = useState("Select Category");
    const [brandFilter, setBrandFilter] = useState("Select Brand");
    const [chipTypeFilter, setChipTypeFilter] = useState("Select Chip Type")
    const [deviceFirmwareData, setDeviceFirmwareData] = useState([]);
    const [isDeviceFirmwareDataLoading, setIsDeviceFirmwareDataLoading] = useState(false);
    const [showFirmwareDataList, setShowFirmwareDataList] = useState(false)
    const [updateFlag, setUpdateFlag] = useState(undefined);

    useEffect(() => {
        M.AutoInit();
    }, [updateFlag, isDeviceFirmwareDataLoading, deviceFirmwareData, categoryFilter])

    useEffect(() => {
        setShowFirmwareDataList(false)
    }, [categoryFilter, brandFilter, chipTypeFilter])

    useEffect(() => {
        if (!categoryFilter || categoryFilter.length == 0 || categoryFilter == "Select Category") {
            return
        }
        if (!brandFilter || brandFilter.length == 0 || brandFilter == "Select Brand") {
            return
        }
        if (!chipTypeFilter || chipTypeFilter.length == 0 || chipTypeFilter == "Select Chip Type") {
            return
        }
        getFirmwareData();

    }, [updateFlag]);

    function downloadFile(firmwareVersion, fileType) {
        let params = {
            'brand': brandFilter,
            'category': categoryFilter,
            'version': firmwareVersion,
            'chipType': chipTypeFilter
        }

        downloadDeviceFactoryFirmwareFile(params, fileType)
    };

    function filterDeviceFirmwareData() {
        if (!categoryFilter || categoryFilter.length == 0 || categoryFilter == "Select Category") {
            setAlert("Category Cannot be Empty", 'danger')
            return
        }

        if (!brandFilter || brandFilter.length == 0 || brandFilter == "Select Brand") {
            setAlert("Brand Cannot be Empty", 'danger')
            return
        }

        if (!chipTypeFilter || chipTypeFilter.length == 0 || chipTypeFilter == "Select Chip Type") {
            setAlert("Chip Type Cannot be Empty", 'danger')
            return
        }

        setUpdateFlag(Math.random());
    };

    function resetFilters() {
        setCategoryFilter("Select Category")
        setBrandFilter("Select Brand")
        setChipTypeFilter("Select Chip Type")
        setUpdateFlag(Math.random())
    };

    const getFirmwareData = () => {

        setIsDeviceFirmwareDataLoading(true);

        let filterParams = {}
        if (categoryFilter && categoryFilter.length != 0 && categoryFilter != "Select Category") {
            filterParams['category'] = categoryFilter
        }

        if (brandFilter && brandFilter.length != 0 && brandFilter != "Select Brand") {
            filterParams['brand'] = brandFilter
        }

        if (chipTypeFilter && chipTypeFilter.length != 0 && chipTypeFilter != "Select Chip Type") {
            filterParams['chipType'] = chipTypeFilter
        }

        getDeviceFactoryFirmwareData(filterParams)
            .then((res) => {
                setIsDeviceFirmwareDataLoading(false);
                setShowFirmwareDataList(true)
                setDeviceFirmwareData(res)
            })
            .catch((err) => {
                setIsDeviceFirmwareDataLoading(false);
                setAlert(err?.response?.data?.message ?? "Failed to Get Factory Firmware Details", "danger")
            });
    };

    // if (isDeviceFirmwareDataLoading) {
    //     return <div style={{ display: "grid", placeItems: "center", height: "20vh" }}>
    //         <CircularProgress size="5em" style={{ color: "#AFB42B" }} />
    //     </div>
    // }

    return (<Fragment>
        <div className="content-block bottom-shadow">
            <div className="py-15 ">
                <div className="row mb-0">
                    <div className="col pt-10">
                        <div className="model-srch reset width-md">
                            <select
                                className="custom-select select-reset radius-20"
                                style={{ "display": "block !important" }}
                                name="categoryFilter"
                                onChange={(e) => {
                                    setCategoryFilter(e.target.value);
                                }}
                                value={categoryFilter}
                            >
                                {["Select Category", ...allowedDeviceCategories].map((element) => (
                                    <option value={element}>{element}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="col pt-10">
                        <div className="model-srch reset width-md">
                            <select
                                className="custom-select select-reset radius-20"
                                style={{ "display": "block !important" }}
                                name="brandFilter"
                                onChange={(e) => {
                                    setBrandFilter(e.target.value);
                                }}
                                value={brandFilter}
                            >
                                {["Select Brand", ...allowedCompanyBrand].map((element) => (
                                    <option value={element}>{element}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="col pt-10">
                        <div className="model-srch reset width-md">
                            <select
                                className="custom-select select-reset radius-20"
                                style={{ "display": "block !important" }}
                                name="chipTypeFilter"
                                onChange={(e) => {
                                    setChipTypeFilter(e.target.value);
                                }}
                                value={chipTypeFilter}
                            >
                                {
                                    categoryFilter == customConfig.deviceCategoryODMFRONTLOADWM ?
                                        ["Select Chip Type", ...customConfig.chipTypesByCategory.factoryFLWM].map((element) => (
                                            <option value={element}>{element}</option>
                                        ))
                                        :
                                        categoryFilter == customConfig.deviceCategoryLED ?
                                        ["Select Chip Type", ...customConfig.chipTypesByCategory.LED].map((element) => (
                                            <option value={element}>{element}</option>
                                        ))
                                        :
                                        ["Select Chip Type", ...customConfig.matterChipType].map((element) => (
                                            <option value={element}>{element}</option>
                                        ))
                                }
                            </select>
                        </div>
                    </div>
                    <div className="col pt-10">
                        <button
                            className="btn btn-style-2 orange-bg ml-15 mb-15"
                            onClick={filterDeviceFirmwareData}
                        >
                            Filter
                        </button>
                        <button
                            className="btn btn-style-2 orange-bg ml-15 mb-15"
                            onClick={resetFilters}
                        >
                            Reset
                        </button>
                    </div>
                </div>
            </div>
        </div>
        {
            isDeviceFirmwareDataLoading ?
                <div style={{ display: "grid", placeItems: "center", height: "20vh" }}>
                    <CircularProgress size="5em" style={{ color: "#AFB42B" }} />
                </div>
                :
                showFirmwareDataList && <div className="content-block bottom-shadow py-15 mb-30">
                    <div className="row mb-0">
                        <div className="col s12">
                            <div
                                className="table-wrapper mb-20"
                                style={{
                                    overflowX: "scroll",
                                    scrollBehavior: "auto",
                                    maxHeight: "58vh",
                                    overflowY: "scroll",
                                }}
                            >
                                <table
                                    width="100%"
                                    align="center"
                                    className="table style-4 mb-20 manufacturedDevicesTable"
                                >
                                    <thead
                                        style={{ position: "sticky", top: 0, zIndex: 50, background: "white" }}
                                    >
                                        <tr>
                                            {Object.keys(headers).map((key) => (
                                                <th>{headers[key]}</th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {deviceFirmwareData.length === 0 ? (
                                            <tr>
                                                <td colSpan={Object.keys(headers).length}>
                                                    No Data found
                                                </td>
                                            </tr>
                                        ) : (
                                            deviceFirmwareData
                                                .map((element, index) => {
                                                    return (
                                                        <tr key={index} id={index}>
                                                            {Object.keys(headers).map((key) => {
                                                                return (
                                                                    key == "deviceCount" ?
                                                                        <td
                                                                            className={
                                                                                String(element[key]).length > 200
                                                                                    ? "breakWord"
                                                                                    : ""
                                                                            }
                                                                        >
                                                                            {element[key]}
                                                                        </td>
                                                                        :
                                                                        key == "firmwareImage" ?
                                                                            <td
                                                                                className={
                                                                                    String(element[key]).length > 200
                                                                                        ? "breakWord"
                                                                                        : ""
                                                                                }
                                                                            >
                                                                                <u><a href="javascript:void(0)" onClick={() => {
                                                                                    downloadFile(element["firmwareVersion"], "firmwareImage")
                                                                                }}><CloudDownload style={{ color: "black" }}></CloudDownload></a></u>
                                                                            </td>
                                                                            :
                                                                            key == "firmwareSign" ?
                                                                                <td
                                                                                    className={
                                                                                        String(element[key]).length > 200
                                                                                            ? "breakWord"
                                                                                            : ""
                                                                                    }
                                                                                >
                                                                                    <u><a href="javascript:void(0)" onClick={() => {
                                                                                        downloadFile(element["firmwareVersion"], "firmwareSign")
                                                                                    }}><CloudDownload style={{ color: "black" }}></CloudDownload></a></u>
                                                                                </td>
                                                                                :
                                                                                <td
                                                                                    className={
                                                                                        String(element[key]).length > 200
                                                                                            ? "breakWord"
                                                                                            : ""
                                                                                    }
                                                                                >
                                                                                    {element[key] ? element[key] : ""}
                                                                                </td>
                                                                );
                                                            })}
                                                        </tr>
                                                    );
                                                })
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
        }
    </Fragment>
    );
}

const UploadFirmware = ({
    allowedDeviceCategories,
    allowedCompanyBrand,
    firmwareData: { uploadDeviceFactoryFirmwareInProgress, uploadDeviceFactoryFirmwareSuccess },
    uploadDeviceFactoryFirmware,
    setAlert }) => {

    const classes = useStyles()
    const defaultChipType = "ESPWIFI"

    const [uploadedCategoryName, setUploadedCategoryName] = useState("")
    const [uploadedBrandName, setUploadedBrandName] = useState("")
    const [uploadedFirmwareVersion, setUploadedFirmwareVersion] = useState("")
    const [uploadedFirmwareImage, setUploadedFirmwareImage] = useState("")
    const [uploadedFirmwareSign, setUploadedFirmwareSign] = useState("")
    const [chipType, setChipType] = useState(defaultChipType)

    const firmwareImageInput = useRef()
    const firmwareSignInput = useRef()

    // Adjust the height of menu items in select. Otherwise get hidden behind headers
    const menuProps = {
        style: {
            marginTop: '52px',
            maxHeight: '400px'
        },
    };

    useEffect(() => {
        M.AutoInit();
    }, [])

    function handleUploadDeviceFirmware() {
        if (!uploadedCategoryName || uploadedCategoryName.length == 0) {
            setAlert('Category Name Cannot be Empty', 'danger')
            return
        }
        if (!uploadedBrandName || uploadedBrandName.length == 0) {
            setAlert('Brand Name Cannot be Empty', 'danger')
            return
        }
        if (!uploadedFirmwareVersion || uploadedFirmwareVersion.length == 0 || uploadedFirmwareVersion.match('^[0-9]{1,2}\\.[0-9]{1,2}$') == null) {
            setAlert('Invalid Firmware Version', 'danger')
            return
        }
        if (!uploadedFirmwareImage || uploadedFirmwareImage == "" || !uploadedFirmwareImage.name.endsWith('.tar.gz')) {
            setAlert('Invalid Firmware Image', 'danger')
            return
        }
        if (!uploadedFirmwareSign || uploadedFirmwareSign == "" || !uploadedFirmwareSign.name.endsWith('.sign')) {
            setAlert('Invalid Signature File', 'danger')
            return
        }

        let formData = new FormData()
        formData.append('files', uploadedFirmwareSign)
        formData.append('files', uploadedFirmwareImage)
        formData.append('brand', uploadedBrandName)
        formData.append('category', uploadedCategoryName)
        formData.append('firmwareVersion', uploadedFirmwareVersion)
        formData.append('chipType', chipType)
        formData.append('signatureFileName', uploadedFirmwareSign.name)

        uploadDeviceFactoryFirmware(formData)
    };

    function handleDeviceUploadFirmwareReset() {
        setUploadedCategoryName("")
        setUploadedBrandName("")
        setUploadedFirmwareVersion("")
        setUploadedFirmwareImage("")
        setUploadedFirmwareSign("")
        setChipType(defaultChipType)

        firmwareImageInput.current.value = ""
        firmwareSignInput.current.value = ""
    }

    return (<Fragment>
        <div className="content-block bottom-shadow">
            <div className="py-15 ">
                <Grid container style={{ marginBottom: "20px" }}>
                    <Grid item xs={2} style={{ marginLeft: "10px" }}>
                        <label className="title">Category: </label>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl variant="outlined" className={classes.selectValues}>
                            <InputLabel id="select-outlined-label-category-name" className="title">Select Category</InputLabel>
                            <Select
                                labelId="select-outlined-label-category-name"
                                id="select-outlined-ctageory-name-id"
                                value={uploadedCategoryName}
                                onChange={(e) => {
                                    setUploadedCategoryName(e.target.value)
                                }}
                                label="Select Category"
                                className={classes.selectInput}
                                MenuProps={menuProps}
                            >
                                {
                                    allowedDeviceCategories.map(categoryName => {
                                        return <MenuItem key={categoryName} value={categoryName}>{categoryName}</MenuItem>
                                    })
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container style={{ marginBottom: "20px" }}>
                    <Grid item xs={2} style={{ marginLeft: "10px" }}>
                        <label className="title">Brand: </label>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl variant="outlined" className={classes.selectValues}>
                            <InputLabel id="select-outlined-label-brand-name">Select Brand</InputLabel>
                            <Select
                                labelId="select-outlined-label-brand-name"
                                id="select-outlined-brand-name-id"
                                value={uploadedBrandName}
                                onChange={(e) => {
                                    setUploadedBrandName(e.target.value)
                                }}
                                label="Select Brand"
                                className={classes.selectInput}
                            >
                                {
                                    allowedCompanyBrand.map(brandName => {
                                        return <MenuItem key={brandName} value={brandName}>{brandName}</MenuItem>
                                    })
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container style={{ marginBottom: "20px" }}>
                    <Grid item xs={2} style={{ marginLeft: "10px" }}>
                        <label className="title">Chip Type: </label>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl variant="outlined" className={classes.selectValues}>
                            <InputLabel id="select-outlined-label-category-name" className="title">Select Category</InputLabel>
                            <Select
                                labelId="select-outlined-label-category-name"
                                id="select-outlined-ctageory-name-id"
                                value={chipType}
                                onChange={(e) => {
                                    setChipType(e.target.value)
                                }}
                                label="Select Chip Type"
                                className={classes.selectInput}
                            >
                                {
                                    uploadedCategoryName == customConfig.deviceCategoryODMFRONTLOADWM ?
                                        [...customConfig.chipTypesByCategory.factoryFLWM].map(type => {
                                            return <MenuItem key={type} value={type}>{type}</MenuItem>
                                        })
                                        :
                                        uploadedCategoryName == customConfig.deviceCategoryLED ?
                                        [...customConfig.chipTypesByCategory.LED].map(type => {
                                            return <MenuItem key={type} value={type}>{type}</MenuItem>
                                        })
                                        :
                                        [...customConfig.matterChipType].map(type => {
                                            return <MenuItem key={type} value={type}>{type}</MenuItem>
                                        })
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container style={{ marginBottom: "20px" }}>
                    <Grid item xs={2} style={{ marginLeft: "10px" }}>
                        <label>Firmware Version: </label>
                    </Grid>
                    <Grid item xs={6} style={{ width: "100% important" }}>
                        <input
                            className="add-input xl"
                            placeholder="Enter Firmware Version"
                            type="text"
                            style={{ width: "100% !important" }}
                            value={uploadedFirmwareVersion}
                            onChange={(e) => {
                                setUploadedFirmwareVersion(e.target.value)
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container style={{ marginBottom: "20px" }}>
                    <Grid item xs={2} style={{ marginLeft: "10px" }}>
                        <label className="title">Firmware Image: </label>
                    </Grid>
                    <Grid item xs={6} style={{ width: "100% important" }}>
                        <input
                            className="md border-1"
                            placeholder="Upload Firmware Image"
                            type="file"
                            style={{ width: "100% !important" }}
                            name={uploadedFirmwareImage?.name ?? uploadedFirmwareImage}
                            onChange={(e) => {
                                setUploadedFirmwareImage(e.target.files[0])
                            }}
                            ref={firmwareImageInput}
                        />
                    </Grid>
                </Grid>
                <Grid container style={{ marginBottom: "50px" }}>
                    <Grid item xs={2} style={{ marginLeft: "10px" }}>
                        <label className="title">Firmware Signature: </label>
                    </Grid>
                    <Grid item xs={6} style={{ width: "100% important" }}>
                        <input
                            className="md border-1"
                            placeholder="Upload Firmware Signature"
                            type="file"
                            style={{ width: "100% !important" }}
                            name={uploadedFirmwareSign?.name ?? uploadedFirmwareSign}
                            onChange={(e) => {
                                setUploadedFirmwareSign(e.target.files[0])
                            }}
                            ref={firmwareSignInput}
                        />
                    </Grid>
                </Grid>
                <div className="row">
                    <button
                        className="btn btn-style-2 orange-bg"
                        onClick={handleUploadDeviceFirmware}
                        disabled={uploadDeviceFactoryFirmwareInProgress ? true : false}
                        style={{ marginLeft: "10px" }}
                    >
                        {
                            uploadDeviceFactoryFirmwareInProgress ? <Fragment>
                                <CircularProgress size="2em" style={{ color: "#AFB42B", marginTop: "5px" }} />
                            </Fragment>
                                : "Upload"
                        }
                    </button>
                    <button
                        className="btn btn-style-2 orange-bg" onClick={handleDeviceUploadFirmwareReset}
                        style={{ marginLeft: "20px" }}
                    >
                        Reset
                    </button>
                </div>
            </div>
        </div>
    </Fragment>
    );
}

const mapStateToProps = (state) => ({
    allowedDeviceCategories: state.auth.allowedDeviceCategories,
    allowedCompanyBrand: state.auth.allowCompanyBrand,
    firmwareData: state.firmware
});

const mapDispatchToProps = (dispatch) => ({
    setAlert: (message, status) => dispatch(setAlert(message, status)),
    uploadDeviceFactoryFirmware: (formData) => dispatch(uploadDeviceFactoryFirmware(formData)),
    downloadDeviceFactoryFirmwareFile: (params, fileType) => dispatch(downloadDeviceFactoryFirmwareFile(params, fileType))
});

export default connect(mapStateToProps, mapDispatchToProps)(DeviceFactoryFirmware);

